/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ShoppingCartOutlined } from "@ant-design/icons"; // Import shopping cart icon
import "../Styles/UpdateNavbar.css"; // Ensure this imports the updated CSS
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Profile icon
import InfoIcon from "@mui/icons-material/Info"; // Information icon
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"; // Gift icon
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";

import { Link } from "react-router-dom"; // For navigation
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

import iraq from "./image/iraq.png";
import Kurdistan from "./image/kurdstan.png";
// import menu from "./image/LOGO.png";
import i18n from "../../i18n";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DrawerComp = ({ size, setShow }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("selectedTheme") === "dark"
  );
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  //todo this is Login
  const [login, setLogin] = useState(true);

  const seelogin = async () => {
    const loginCookieValue = await Cookies.get("login");

    if (loginCookieValue === "true") {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };
  useEffect(() => {
    seelogin();
  }, []);
  //!=-=================
  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-theme", darkMode ? "dark" : "light");
    localStorage.setItem("selectedTheme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const onChangeLang = (selectedLang) => {
    Cookies.set("language", selectedLang);
    i18n.changeLanguage(selectedLang);
  };

  const handleLanguageClick = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorElLanguage(null);
    setOpenDrawer(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDarkModeToggle = () => {
    setDarkMode((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "var(--drawer-bg)",
            color: "var(--drawer-text)",
          },
          className: "drawer",
        }}
      >
        <List>
          <ListItemButton
            component={Link}
            to="/"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <HomeIcon className="Text-icon" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              }}
            >
              {t("home")}
            </ListItemText>
          </ListItemButton>

          {login ? (
            <ListItemButton
              component={Link}
              to="/Profile"
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemIcon>
                <AccountCircleIcon className="Text-icon" />
              </ListItemIcon>

              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                }}
              >
                {t("Profile")}
              </ListItemText>
            </ListItemButton>
          ) : (
            <></>
          )}
          <ListItemButton
            component={Link}
            to="/Gift"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <CardGiftcardIcon className="Text-icon" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              }}
            >
              {t("gife")}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/aboutUs"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <InfoIcon className="Text-icon" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              }}
            >
              {t("About")}
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={handleLanguageClick}>
            <ListItemIcon>
              <LanguageIcon className="Text-icon" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              }}
            >
              {t("lang")}
            </ListItemText>
            <ExpandMoreIcon />
          </ListItemButton>
          <Menu
            anchorEl={anchorElLanguage}
            open={Boolean(anchorElLanguage)}
            onClose={handleLanguageClose}
          >
            <MenuItem onClick={() => onChangeLang("ku")}>
              <img
                src={Kurdistan}
                alt="Kurdistan Flag"
                height={25}
                width={25}
              />
              {t("Kurdish")}
            </MenuItem>
            <MenuItem onClick={() => onChangeLang("ar")}>
              <img src={iraq} alt="Arabic Flag" height={25} width={25} />
              {t("Arabic")}
            </MenuItem>
          </Menu>
          {login ? null : (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
              <Button
                variant="contained"
                className="sign-in-button" // Add the CSS class here
                component={Link}
                to="/login"
                onClick={() => setOpenDrawer(false)}
              >
                {t("Sign in")}
              </Button>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
            <IconButton
              onClick={() => setDarkMode((prev) => !prev)}
              sx={{
                color: darkMode ? "#ffb74d" : "#424242",
                width: "40px", // Set the width
                height: "40px", // Set the height
                marginLeft: 2, // Add space between the cart and dark mode icon
              }}
            >
              <span style={{ fontSize: "30px" }}>
                {darkMode ? "🌙" : "☀️"} {/* Set the desired size here */}
              </span>
            </IconButton>
          </Box>
        </List>
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#3b5998", fontSize: "30px" }} // font size applied here
          >
            <FontAwesomeIcon icon={faFacebook} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#c13584", fontSize: "30px" }} // font size applied here
          >
            <FontAwesomeIcon icon={faInstagram} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#25D366", fontSize: "30px" }} // font size applied here
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </IconButton>

          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#000000", fontSize: "30px" }} // font size applied here
          >
            <FontAwesomeIcon icon={faTiktok} />
          </IconButton>
        </Box>
      </Drawer>
      <div className="icon-container">
        <div
          className="cartMobile"
          onClick={() => setShow(false)}
          data-count={size} // Using data-count attribute for displaying item count
        >
          <span>
            <Link className="cartMobile" to="/Cart">
              <ShoppingCartOutlined
                style={{ fontSize: 33 }} // No color here; it's handled in CSS
              />
            </Link>
          </span>
        </div>

        <IconButton
          sx={{
            color: darkMode ? "#fff" : "#000", // Set color based on dark mode
            marginLeft: "auto",
            fontWeight: "bold",
            padding: "12px",
            fontSize: "1.5rem",
            borderRadius: "50%",
            backgroundColor: darkMode ? "#333" : "#f0f0f0",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
            transition: "transform 0.3s ease",
            "&:hover": {
              backgroundColor: darkMode ? "#555" : "#e0e0e0",
              transform: "scale(1.2)",
            },
          }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon fontSize="inherit" />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default DrawerComp;
