/* eslint-disable no-template-curly-in-string */
import { Form, Button, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import "../Styles/regster.css";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
//todo -------------------------------------------
// import { useLocation } from "react-router-dom";
//todo -------------------------------------------

const Register = () => {
  //todo ---------------------------------------------------
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const orderJson = queryParams.get("orderJson");
  //todo ---------------------------------------------------
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referentialCode, setReferentialCode] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState(0);
  const [form] = Form.useForm();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [userCode, setUserCode] = useState("");

  useEffect(() => {
    const getApi = async () => {
      if (Cookies.get("login") === "true") {
        window.location.href = "/profile";
      } else {
        const URI = "https://ecommerce-api.webappdevs.com/api/users";

        try {
          const res = await fetch(URI);
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
        } catch (error) {
          // console.error("Error fetching API:", error);
        }
      }
    };

    getApi();
  }, []);

  const PostRegister = () => {
    const URI = "https://ecommerce-api.webappdevs.com/api/register";

    const requestBody = {
      email,
      password,
      referential_code: referentialCode,
      name,
      address,
      number,
    };

    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        Cookies.set("login", true);
        Cookies.set("user", JSON.stringify(data.user));

        const code = data.user.referential_code;
        setUserCode(code);
        //todo -------------------------------------------------
        //todo call order post
        const URL = "https://ecommerce-api.webappdevs.com/api/orders";
        const urlParams = new URLSearchParams(window.location.search);
        const decodedOrderJson = decodeURIComponent(urlParams.get("orderJson"));
        const orderJsonObj = JSON.parse(decodedOrderJson);
        await fetch(URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ...orderJsonObj,
            ...{
              address: data.user.address,
              created_at: data.user.created_at,
              email: data.user.email,
              user_id: data.user.id.toString(),
              username: data.user.name,
              phone_number: data.user.number,
              referential_code: data.user.referential_code,
              referred_by: data.user.referred_by,
              updated_at: data.user.updated_at,
              uuid: data.user.uuid,
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setSuccessModalVisible(true);
          })
          .catch((error) => {});

        setSuccessModalVisible(true);

        form.resetFields();
      })
      .catch((error) => {
        // console.error("Error:", error);

        // Handle error message here
        message.error(t("filde"));
      });
  };

  //todo -------------------------------------------------
  // const URL = "https://ecommerce-api.webappdevs.com/api/orders";
  //       const URL = "https://www.bardawamplus.com/user";
  //       const orderJsonObj = JSON.parse(decodeURIComponent(orderJson));

  //       await fetch(
  //         `${URL}?orderJson=${encodeURIComponent(
  //           JSON.stringify(orderJsonObj)
  //         )}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setSuccessModalVisible(true);
  //         })
  //         .catch((error) => {
  //           console.error("Error submitting order:", error);
  //         });

  //       setSuccessModalVisible(true);
  //       form.resetFields();
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       message.error(t("filde"));
  //     });
  // };
  //todo----------------------------------------------------------------------

  const validateNumber = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter only numbers");
  };

  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  const validateMessages = {
    types: {
      email: "${label} is not a valid email!",
      required: "${label} is required!",
    },
  };

  const handleModalOk = () => {
    setSuccessModalVisible(false);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 20,
    },
  };

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        marginTop: 50,
        marginBottom: 50,
        padding: 20,
        border: "1px solid #ddd",
        borderRadius: 8,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        direction: "rtl",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
          fontFamily: "Noto Naskh Arabic, serif",
          fontOpticalSizing: "auto",
          fontStyle: "normal",
          fontWeight: "bold",
        }}
      >
        <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>
          {t("Registerr")}
        </h1>
        <Form
          form={form}
          {...layout}
          validateMessages={validateMessages}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            label={t("Email")}
            rules={[
              {
                required: true,
                type: "email",
                message: t("Type your Email"),
              },
            ]}
          >
            <Input
              placeholder={t("Type your Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("Password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("Please input your password!"),
              },
            ]}
          >
            <Input.Password
              placeholder={t("Enter password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("Code Invited")}
            name="referentialCode"
            rules={[
              {
                required: true,
                message: t("Please enter your Code invited"),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t("Enter your Code invited")}
              value={referentialCode}
              onChange={(e) => setReferentialCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("Full Name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("Please enter your full name"),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t("Enter your full name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("Address")}
            name="address"
            rules={[
              {
                required: true,
                message: t("Please enter your address"),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t("Enter your address")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("Number Input")}
            name="number"
            rules={[
              { required: true, message: t("Please enter a number") },
              { validator: validateNumber },
            ]}
          >
            <Input
              type="number"
              placeholder={t("Enter a number")}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              PostRegister();
            }}
          >
            {t("Register")}
          </Button>
        </Form>
        <Modal
          open={successModalVisible}
          onOk={handleModalOk}
          onCancel={() => setSuccessModalVisible(false)}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                window.location.reload();
                handleModalOk();
              }}
            >
              OK
            </Button>,
          ]}
          style={{
            textAlign: "center",
            borderRadius: "8px",
            maxWidth: "400px",
            margin: "auto",
            direction: "rtl",
          }}
        >
          <h2 style={{ color: "#1890ff" }}>
            {t("Congratulations")}, {name}!
          </h2>
          <p>
            {t("Your registration was successful. Welcome to our community!")}
          </p>
          <p style={{ fontWeight: "bold" }}>
            {t("This is your referential code:")}
          </p>
          <p style={{ fontSize: "1.2em", color: "#52c41a" }}>{userCode}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Register;
