import "../Styles/Aboutus.css";

import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useEffect, useState } from "react";
import MapLearn from "../Map/MapLearn";
const AboutUs = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="ALL">
      <div className="Text-Top">
        <h1 className="Text-About">{t("AboutA")}</h1>

        <h1 className="Text-About">{t("AboutB")}</h1>
        <MapLearn />
      </div>
      {showScrollButton && (
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      )}
    </div>
  );
};

export default AboutUs;
